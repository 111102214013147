@media (min-width: 1000px) {

    .MainPage {
        width: 100%;
        margin-bottom: 57px;
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 34px;
        height: 147px;
        border: 1px solid #6e6e6e;
        background: #DDDDDD;
    }

    .body {
        margin-left: 25px;
        margin-right: 39px;
        box-sizing: border-box;

    }

    .search_div {
        width: 72px;
        height: 66px;
        background: #00A3FF;
        border: 1px solid #A2A3A4;
        margin-left: auto;
        margin-right: 39px;
        box-sizing: border-box;

    }

    .search_img {
        margin-top: 14px;
        margin-left: 12px;
        margin-right: 11px;
        margin-bottom: 13px;
        box-sizing: border-box;

    }

    .search_input {
        font-family: 'Roboto';
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
        align-items: center;
        color: #000000;
        width: 100%;
        height: 64px;
        margin-left: 23px;
        margin-top: 14px;
        margin-bottom: 14px;
        border: 1px solid #A2A3A4;
        padding-left: 23px;
        box-sizing: border-box;

    }

    .search_btn {
        margin-right: 0px;
        margin-left: auto;
        border: 1px solid #6e6e6e;
        box-sizing: border-box;

    }

    .cards {
        display: flex;
        margin-bottom: 51px;
        flex-wrap: wrap;
        row-gap: 23px;
        column-gap: 19px;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

    }

    .one_card {
        position: relative;
        border: 1px solid #6e6e6e;
        display: flex;
        flex-direction: column;
        min-height: 218px;
        min-width: 440px;
        width: 440px;
        box-sizing: border-box;

    }

    .name_project {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        margin-top: 7px;
        margin-left: 16.3px;
        margin-right: auto;
        margin-bottom: 18px;
        cursor: pointer;
        color: #000000;
    }

    .avatar_author {
        display: flex;
        margin-left: 16.3px;

    }

    .avatar {
        margin-right: 26.47px;
        width: 50px;
        height: 50px;
    }

    .avatar_img {
        width: 100%;
        height: 100%;
        border-radius: 25px;
    }

    .author {
        display: flex;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        /* identical to box height */
        cursor: pointer;

        display: flex;
        align-items: center;

        color: #000000;
    }

    .description_block {
        margin: 10px;


    }

    .description {
        white-space: pre-line;
    }

    .drop_down_description {
        border: 1px solid #6e6e6e;
    }

    .description_title {
        border: 1px solid #6e6e6e;
    }

    .tap {
        position: absolute;
        right: 40px;
    }

    .counts_info {
        display: flex;
        margin-left: 15.04px;
        margin-top: 13px;
        margin-bottom: 14px;
    }

    .star_img {
        margin-right: 18.87px;
        height: 25px;
        width: 25px;
    }

    .watcher_img {
        margin-right: 18.87px;
        height: 25px;
        width: 25px;
    }

    .stargazers_count {
        display: flex;
        align-items: center;
    }

    .watchers_count {
        display: flex;
        margin-left: 30.19px;
        align-items: center;
    }

    .count_div {
        display: flex;
        justify-content: center;
        margin-left: 25px;
        margin-right: 39px;
    }

    .div_btn {
        position: relative;
    }

    .minus_img {
  
        position: relative;
    }

    .minus_count {
        width: 52px;
        height: 52px;
        background: white;
        /* border: 1px solid #A2A3A4; */
        border: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }

    .plus_count {
        width: 52px;
        height: 52px;
        background: white;
        /* border: 1px solid #A2A3A4; */
        cursor: pointer;
        border: none;
        padding: 0;
        margin: 0;
    }

    .plus_img {

        position: relative;
    }

    .pages {
        display: flex;
    }

    .number {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }

    .page_item {
        width: 50px;
        height: 50px;
        border: 1px solid #A2A3A4;
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
    }

    .page_item_target {
        width: 50px;
        height: 50px;
        /* border: 1px solid #A2A3A4; */
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00A3FF;
        border: none;
    }

    .comment_div {
        display: flex;
        margin-left: 15.04px;
        margin-right: 15.04px;
        height: 54px;
    }

    .comment_input {
        width: 100%;

    }

    .comment_img_div {
        background: #00A3FF;
        border: 1px solid #A2A3A4;
        display: flex;
        align-items: center;
        width: 63.93px;
    }

    .comment_img {
        width: 40px;
        height: 40px;
        margin-right: 0;
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-left: 11.26px;
        margin-right: 12.67px;

    }

    .cross {
        position: absolute;
        width: 25px;
        height: 25px;
        right: 5px;
        top: 5px;

    }

    .count_menu {
        display: flex;
        margin-right: 43%;

    }

    .dropbtn {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        width: 71px;
        height: 44px;
    }

    .dropdown {
        position: relative;
        margin-left: 0px;
        margin-right: auto;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
}

@media (max-width: 1000px) {

    .MainPage {
        width: 100%;
        margin-bottom: 57px;
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 34px;
        height: 147px;
        border: 1px solid #6e6e6e;
        background: #DDDDDD;
    }

    .body {
        margin-left: 25px;
        margin-right: 39px;
    }

    .search_div {
        width: 72px;
        height: 66px;
        background: #00A3FF;
        border: 1px solid #A2A3A4;
        margin-left: auto;
        margin-right: 39px;
    }

    .search_img {
        margin-top: 14px;
        margin-left: 12px;
        margin-right: 11px;
        margin-bottom: 13px;
    }

    .search_input {
        font-family: 'Roboto';
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        display: flex;
        align-items: center;
        color: #000000;
        width: 100%;
        height: 64px;
        margin-left: 23px;
        margin-top: 14px;
        margin-bottom: 14px;
        border: 1px solid #A2A3A4;
        padding-left: 23px
    }

    .search_btn {
        margin-right: 0px;
        margin-left: auto;
        border: 1px solid #6e6e6e;
    }

    .cards {
        display: flex;
        margin-bottom: 51px;
        flex-wrap: wrap;
        row-gap: 23px;
        column-gap: 19px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .one_card {
        position: relative;
        border: 1px solid #6e6e6e;
        display: flex;
        flex-direction: column;
        min-height: 218px;
        width: 100%;
    }

    .name_project {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        margin-top: 7px;
        margin-left: 16.3px;
        margin-right: auto;
        margin-bottom: 18px;
        cursor: pointer;
        color: #000000;
    }


    .avatar_author {
        display: flex;
        margin-left: 16.3px;
    }

    .avatar {
        margin-right: 26.47px;
        width: 50px;
        height: 50px;
    }

    .avatar_img {
        width: 100%;
        height: 100%;
        border-radius: 25px;
    }

    .author {
        display: flex;
        align-items: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #000000;
    }

    .description_block {
        margin: 10px;
    }

    .description {
        white-space: pre-line;
    }

    .drop_down_description {
        border: 1px solid #6e6e6e;
    }

    .description_title {
        border: 1px solid #6e6e6e;
    }

    .tap {
        position: absolute;
        right: 40px;
    }

    .counts_info {
        display: flex;
        margin-left: 15.04px;
        margin-top: 13px;
        margin-bottom: 14px;
    }

    .star_img {
        margin-right: 18.87px;
        height: 25px;
        width: 25px;
    }

    .watcher_img {
        margin-right: 18.87px;
        height: 25px;
        width: 25px;
    }

    .stargazers_count {
        display: flex;
        align-items: center;
    }

    .watchers_count {
        display: flex;
        margin-left: 30.19px;
        align-items: center;
    }

    .count_div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 25px;
        margin-right: 39px;
    }

    .div_btn {
        position: relative;
    }

    .minus_img {

        position: relative;
    }

    .minus_count {
        width: 52px;
        height: 52px;
        background: white;
        border: none;
        cursor: pointer;
        padding: 0;
        margin: 0;

    }

    .plus_count {
        width: 52px;
        height: 52px;
        background: white;
        cursor: pointer;
        border: none;
        padding: 0;
        margin: 0;
    }

    .plus_img {

        position: relative;
    }

    .pages {
        display: flex;
    }

    .number {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
    }

    .page_item {
        width: 50px;
        height: 50px;
        border: 1px solid #A2A3A4;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .page_item_target {
        width: 50px;
        height: 50px;
        border: 1px solid #A2A3A4;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00A3FF;
    }

    .comment_div {
        display: flex;
        margin-left: 15.04px;
        margin-right: 15.04px;
        height: 54px;
    }

    .comment_input {
        width: 100%;
    }

    .comment_img_div {
        background: #00A3FF;
        border: 1px solid #A2A3A4;
        display: flex;
        align-items: center;
        width: 63.93px;
    }

    .comment_img {
        width: 40px;
        height: 40px;
        margin-right: 0;
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-left: 11.26px;
        margin-right: 12.67px;
    }

    .cross {
        position: absolute;
        width: 25px;
        height: 25px;
        right: 5px;
        top: 5px;
    }

    .count_menu {
        display: flex;
    }

    .dropbtn {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        width: 71px;
        height: 44px;
    }

    .dropdown {
        position: relative;
        margin-left: 0px;
        margin-right: auto;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }
}
